export const TAG = 'HOTEL_DIGITAL';

//test mac: 68:8C:A0:9E:71:94
export const DEBUG = false;

// RESTAURANTE DESARROLLO
/*export const LICENSE = '5ac5e185629de';
export const NAME_DEVICE = 'demo08';
export const APP_ID = '1';*/

// LA REINA
/*export const LICENSE = '5bc72654b641a';
export const NAME_DEVICE = 'demo03';
export const APP_ID = '47';*/

// DEMO NUEVO MENU
export const LICENSE = '5cf7a8b32ee09';
export const NAME_DEVICE = 'demo02';
export const APP_ID = '77';

/*
export const LICENSE =  '5c34c8b1d7eab';
export const NAME_DEVICE = 'demo02';
export const APP_ID = '45';*/


// CONFIG APP
export const PREPRODUCCION_MODE = false;
export const STAND_BY_ACTIVE = true;
export const SHOW_TITLES = true;
export const TIME_CHECK_DATA = 5000;
export const UPDATE_ONLY_SHOWING_STANDBY = true;
export const MIN_TIME_UPDATE_DATA = 600; // in seconds
export const SHOW_PRODUCTS_IN_BANNER = true;
export const SHOW_BUTTON_FOOD = false;
export const SHOW_BOOKING = false;

// REQUEST
export const REQUEST_SELECTIVE = 1;
export const REQUEST_ALL = 2;
export const REQUEST_NONE = 3;

// MENU APP SECTION
export const SECTION_MENU_GRID = 4;

// VISIBILITY CONSTANTS
export const ITEM_IS_ACTIVE = 1;

 // APP TYPES
export const TYPE_RESTAURANT = 1;
export const TYPE_HOTEL = 2;
export const TYPE_ESTABLISHMENT = 3;

// PRODUCT INTERFACE CONSTANTS
export const INTERFACE_PRODUCT_LIST_NO_PHOTO = 7;
export const INTERFACE_PRODUCT_LIST_PHOTO = 1;
export const INTERFACE_PRODUCT_PHOTO_AND_DESCRIPTION = 2;
export const INTERFACE_PRODUCT_PHOTO_NO_DESCRIPTION = 3;
export const INTERFACE_PRODUCT_TYPE_EVENT = 4;
export const INTERFACE_PRODUCT_NO_PHOTO = 5;
export const INTERFACE_PRODUCT_HEADER_AND_DESCRIPTION = 0;

// ITEM TYPE CONSTANTS
export const ITEM_TYPE_SUBMENU = 1;
export const ITEM_TYPE_LINK = 2;
export const ITEM_TYPE_PRODUCT = 3;

// LINK TYPE CONSTANTS
export const TYPE_LINK_URL = 1;
export const TYPE_LINK_APP = 2;
export const TYPE_LINK_SURVEY = 3;

// SURVEY QUESTIONS TYPE
export const TYPE_QUESTION_3_FACES = '1';
export const TYPE_QUESTION_4_FACES = '2';
export const TYPE_QUESTION_5_FACES = '3';
export const TYPE_QUESTION_UNIQUE_ANSWER = '4';
export const TYPE_QUESTION_MULTIPLE_ANSWER = '5';
export const TYPE_QUESTION_TEXT_SQUARE = '6';
export const TYPE_QUESTION_FREE_ANSWER = '7';

// TYPES REFERENCE
export const TYPE_ALL = 'ALL';
export const TYPE_PILLOWS = 'ALH';
export const TYPE_SERVICES = 'BAR';
export const TYPE_EXPERIENCES = 'EXP';

// ACTIONS STATS
export const ACTION_TOUCH = 1;
export const ACTION_ORDER = 2;
export const ACTION_ADD_PRODUCT = 3;
export const ACTION_CALL_WAITER = 4;
export const ACTION_BILL_CARD = 5;
export const ACTION_BILL_CASH = 6;
export const ACTION_PRINT_ORDER = -1;

// VARS STATS
export const MAC = "mac";
export const STABLISHMENT = "id_establishment";
export const MENU_APP = "id_menu_app";
export const LANG = "id_language";
export const ACTION = "id_stat_action";
export const MENU_LEVEL = "id_menu_level";
export const PRODUCT_ID_RELATIVE = "id_relative";
export const DATE_CREATION = "created_at";
export const TOTAL = "amount";

// URLS
export const URL_BASE = "https://api.iurban.es/";
export const PRE_URL_BASE = "https://apipreproduccion.iurban.es/";

export const URL_LICENSE = 'https://api.iurban.es/android/apk';
export const URL_IURBAN_APPS = 'https://api.iurban.es/android/aplicaciones';
export const URL_IURBAN = 'https://api.iurban.es/android/aplicacion/';
export const STATS_SURVEY_URL = PREPRODUCCION_MODE ? PRE_URL_BASE + 'cms/encuesta-intento' : URL_BASE + 'cms/encuesta-intento';

// URLS OFIHOTEL
export const URL_OFIHOTEL = PREPRODUCCION_MODE ? 'https://apipreproduccion.iurban.es/api/application/hoteldigitalWithToken'
: 'https://api.iurban.es/api/application/hoteldigitalWithToken';
export const URL_OFIHOTEL_ORDER = 'https://apipreproduccion.iurban.es/api/registerservice';
// internal token to getMenu...
export const URL_OFIHOTEL_TOKEN = 'https://apipreproduccion.iurban.es/api/gentoken';


// URLS DST
export let category: any;
export let deviceId = 'DESKTOP-BVQGCVD:4TWR-LAMH-QUBF-VBFX';
// export let number : any;
const URL_BASE_DST = 'http://192.168.1.114:8081/dstwcf';

// URLS LOCALHOST
export const URL_LOCALHOST = 'http://192.168.1.134/api/dst/jsonapp';
export const URL_LOCALHOST_ORDER = 'http://192.168.1.134/api/dst/jsonapp';
// export let number : any;

// SESSION
export const URL_DST_LOGIN = URL_BASE_DST + '/CarteStartApplication/?deviceid=' + deviceId + '&versionapk=1';
export const URL_DST_SESSION = URL_BASE_DST + '/GetDataConf/?deviceid=' + deviceId;

// GET IMAGES
export const URL_DST_CHECK_TO_LOAD_IMAGES = URL_BASE_DST + '/CheckToLoadImages/?deviceid=' + deviceId;
export const URL_DST_GET_ITEM_IMAGESLIST = URL_BASE_DST + '/CarteGetItemImagesList/?deviceid=' + deviceId;
export const URL_DST_CARTE_GET_IMAGES = URL_BASE_DST + '/CarteGetImages/?deviceid=' + deviceId;

export const URL_DST_GET_CARTELIST = URL_BASE_DST + '/CarteGetList/?deviceid=' + deviceId;
export const URL_DST_GET_LANGUAGES = URL_BASE_DST + '/CarteGetLanguajes/?deviceid=' + deviceId;

export const URL_DST_GET_SUPERCATEGORIES = URL_BASE_DST + '/CarteGetCategories/?deviceid=' + deviceId;
export const URL_DST_GET_SUPERCATEGORIES_BY_LANG = URL_BASE_DST + '/CarteGetCategoriesDesc/?deviceid=' + deviceId + '&category=' + category;

export const URL_DST_GET_CATEGORIES = URL_BASE_DST + '/CarteGetSubCategories/?deviceid=' + deviceId;
export const URL_DST_GET_CATEGORIES_BY_LANG = URL_BASE_DST + '/CarteGetSubCategoriesDesc/?deviceid=' + deviceId;

export const URL_DST_GET_SUBCATEGORIES = URL_BASE_DST + '/CarteGetSubSubCategories/?deviceid=' + deviceId;

export const URL_DST_GET_ARTICLES = URL_BASE_DST + '/GetAllCarteItems/?deviceid=' + deviceId;
export const URL_DST_GET_ARTICLE_DESCRIPTION = URL_BASE_DST + '/GetCarteArticleDescriptions/?deviceid=' + deviceId + '&article={article}';

// STATS
export const STATSURL = "https://apipreproduccion.iurban.es/android/estadistica";
export const STATS_DEVICE = PREPRODUCCION_MODE ? PRE_URL_BASE + "android/conexion" : URL_BASE + "android/conexion";

// DATA_FROM
export const IURBAN = 'iurban';
export const OFIHOTEL = 'ofihotel';
export const DST = 'dst';
export const LOCALHOST = 'localhost';

// API
export const API = OFIHOTEL;
