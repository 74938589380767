import { Component, OnInit, HostListener } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { RestService } from '../services/rest.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.page.html',
  styleUrls: ['./modal.page.scss'],
})
export class ModalPage implements OnInit {
  filters: any;
  showParent = true;
  tempSelectedFilters = new Array();
  selectedPrice: any;
  close = false;
  showBooking = false;
  date: string;
  time: string;
  attendeesAmount = 1;
  bookingName: string;
  comments: string;

  requestDate = true;
  requestTime = true;
  requestAttendees = true;
  requestBookingName = true;
  requestComments = true;

  @HostListener('touchend', ['$event'])
  onEnd(event) {
   
        this.rest.restartStandByTime();
    
  }

  constructor(
    private modalController: ModalController,
    private navParams: NavParams, public rest: RestService,
  ) { }

  ngOnInit() {
    if(this.rest.response!=undefined){
    this.filters = this.rest.response.filters_without;
    }
    console.table(this.navParams);
    // this.alertType = this.navParams.data.alertType;

    // console.log('alert type ' + this.alertType);
    document.documentElement.style.setProperty('--item-background-color', this.rest.colorbackgroundItems);
  }

  priceIsEmpty(indexPrice) {
    // return this.rest.currentProduct.price_translations.values[indexPrice] === '';
    false;
  }

  getNamePrice(indexPrice: number) {
    let name_price = this.rest.currentProduct.price_translations.keys != undefined ?
    this.rest.currentProduct.price_translations.keys[this.rest.usedLang][indexPrice] : '';

    if (name_price != undefined) {
      name_price = name_price.toUpperCase() + ' .... ' + this.rest.currentProduct.price_translations.values[indexPrice] + ' €';
    }
    return name_price;
  }

  onFilterChecked(checkbox: any, filter: any) {
      if (checkbox.checked) {
          this.tempSelectedFilters.push( filter );
      } else {
          this.tempSelectedFilters.splice(this.rest.selectedFilters.findIndex(x => x === filter), 1);
      }
  }

  onModeChecked(checkbox: any, mode: any) {
    if (checkbox.checked) {
        this.rest.selectedModes.push( mode );
    } else {
        this.rest.selectedModes.splice(this.rest.selectedFilters.findIndex(x => x === mode), 1);
    }
  }

  onParentClick(parentIndex: number) {
    for (let i = 0; i < this.rest.response.filters_without.length; i++) {
      // this.rest.parentsFilters[i] = { showParent: false };
      for (let e = 0; e < this.rest.response.filters_without[i].filter_elements.length; e++) {
          this.rest.checksFilters[i][e] = { showParent: !this.rest.checksFilters[i][e].showParent, isChecked: false};
      }
    }
    this.rest.parentsFilters[parentIndex] = !this.rest.parentsFilters[parentIndex];
  }

  aceptFilters() {
    this.rest.selectedFiltersString = '';
    this.rest.selectedFilters = new Array();
    for (let i = 0; i < this.tempSelectedFilters.length; i++) {
        this.rest.selectedFilters.push(this.tempSelectedFilters[i]);
        this.rest.selectedFiltersString += ( i === 0 ? '' : ' | ') + this.rest.selectedFilters[i].name_translations[this.rest.usedLang] ;
    }
    this.rest.closeModal();
    console.log('on lcick jejeje');
  }

  clearFilters() {
    this.tempSelectedFilters = new Array();
    for (let i = 0; i < this.rest.response.filters_without.length; i++) {
      for (let e = 0; e < this.rest.response.filters_without[i].filter_elements.length; e++) {
          this.rest.checksFilters[i][e] = { showParent: true, isChecked: false};
      }
    }
  }

  onBillClick(billType: string) {
      if (billType === 'cash') {
        if (this.rest.source !== 'dom' && this.rest.platform.is('android')) {
            (<any>window).UtilsAndroid.printTicket({message: 'Ha pedido la cuenta en efectivo',
            ip: this.rest.getPrinter().ip, port: this.rest.getPrinter().port},
            this.rest.onPrinTicketSuccess.bind(this), this.rest.onPrinTicketFailure.bind(this));
        }
        this.rest.closeModal();
      } else if (billType === 'card') {
        if (this.rest.source !== 'dom' && this.rest.platform.is('android')) {
            (<any>window).UtilsAndroid.printTicket({message: 'Ha pedido la cuenta en efectivo',
            ip: this.rest.getPrinter().ip, port: this.rest.getPrinter().port},
            this.rest.onPrinTicketSuccess.bind(this), this.rest.onPrinTicketFailure.bind(this));
        }
        this.rest.closeModal();
      }
  }

  onClickBtn1() {
    if (this.rest.alertType === 'addProduct') {
      // console.log('on click btn 1 modes' );
      this.rest.countProduct = 1;
      this.rest.closeModal();
    } else if (this.rest.alertType === 'modes') {
        // console.log('on click btn 1 modes' );
        this.rest.closeModal();
    } else if (this.rest.alertType === 'prices') {
        // console.log('on click btn 1 prices');
        this.rest.closeModal();
    } else if (this.rest.alertType === 'booking') {
        // console.log('on click btn 1 prices');
        this.rest.closeModal();
    } else if (this.rest.alertType === 'input') {
        // console.log('on click btn 1 input');
        if (this.rest.modalAction === 'password') {
            this.rest.checkPassword(this.rest.textInput);
        } else if (this.rest.modalAction === 'location') {
            this.rest.storageVar.set('location', this.rest.textInput);
            this.rest.location = this.rest.textInput;
            this.rest.textInput = '';
            this.rest.closeModal();
            //  this.rest.checkPassword(this.rest.textInput);
        }
    } else if (this.rest.alertType === 'bill') {
        console.log('on click btn 1 input');
        // this.rest.checkPassword(this.password);

        this.rest.closeModal();
    } else if (this.rest.alertType === 'confirm') {
      if (this.rest.modalAction === 'waiter') {
          if (this.rest.source !== 'dom' && this.rest.platform.is('android')) {
              (<any>window).UtilsAndroid.printTicket({message: 'Ha llamado al camarero', ip: '192.168.1.112', port: '9100'},
               this.rest.onPrinTicketSuccess.bind(this), this.rest.onPrinTicketFailure.bind(this));
          }
          } else if (this.rest.modalAction === 'deleteProduct') {
            this.rest.deleteProduct(this.rest.productToDelete);
            this.rest.productToDelete = '';
          }
      this.rest.closeModal();
    }
  }

  onClickBtn2() {
    if (this.rest.alertType === 'filters') {
        this.clearFilters();
    } else if (this.rest.alertType === 'modes') {
      if (this.rest.indexMode > 0) {
          this.rest.indexMode--;
          this.rest.titleModal = 'ELIJA: ' +
          this.rest.currentProduct.modes[this.rest.indexMode].name_translations[this.rest.usedLang].toUpperCase();
          this.rest.btnModal3 = { show : true, text: 'SIGUIENTE'};
      } else if (this.rest.indexMode === 0) {
          // this.rest.openModal('prices');
          this.rest.showPrices();
      } else {
          this.rest.indexMode = 0;
          this.rest.btnModal3 = { show : false, text: ''};
      }
    } else if (this.rest.alertType === 'booking') {
          if (this.rest.currentProduct.modes.length > 0) {
              this.rest.showModes();
          } else if (this.rest.currentProduct.price_translations.values.length > 0) {
              this.rest.showPrices();
          } else {
              this.rest.closeModal();
          }
    } else if (this.rest.alertType === 'bill') {
      if (this.rest.source !== 'dom' && this.rest.platform.is('android')) {

          (<any>window).UtilsAndroid.printTicket({message: 'Ha llamado al camarero', ip: '192.168.1.112', port: '9100'},
           this.rest.onPrinTicketSuccess.bind(this), this.rest.onPrinTicketFailure.bind(this));
      }
          this.rest.closeModal();
    }
  }

  onClickBtn3() {
    if (this.rest.alertType === 'addProduct') {
      if (this.rest.currentProduct.modes.length > 0) {
        this.rest.showModes();
      } else if (this.rest.currentProduct.price_translations.values.length > 0) {
        this.rest.showPrices();
      }
    } else if (this.rest.alertType === 'filters') {
        this.aceptFilters();
    } else if (this.rest.alertType === 'modes') {
      if (this.rest.indexMode === this.rest.currentProduct.modes.length - 1) {
          if (this.showBooking === true) {
              this.rest.showBooking();
          } else {
              this.prepareDataProduct();
              this.rest.closeModal();
          }
      } else if (this.rest.indexMode < this.rest.currentProduct.modes.length - 1) {
          this.rest.indexMode++;
          this.rest.titleModal = 'ELIJA: ' +
          this.rest.currentProduct.modes[this.rest.indexMode].name_translations[this.rest.usedLang].toUpperCase();
          this.rest.btnModal2 = { show : true, text: 'ATRAS'};

          if (this.rest.indexMode === this.rest.currentProduct.modes.length - 1) {
            if (this.showBooking === true) {
              this.rest.btnModal3 = { show : true, text: 'SIGUIENTE'};
            } else {
              this.rest.btnModal3 = { show : true, text: 'ACEPTAR'};
            }
          }
      } else {
          // console.log('final2 ');
          if (this.rest.currentProduct.price_translations.values.length > 0) {
              this.rest.btnModal2 = { show : true, text: 'ATRAS'};
          }
      }
    } else if (this.rest.alertType === 'prices') {
          if (this.rest.currentProduct.modes.length > 0) {
              // console.log('on next pressed');
              this.rest.btnModal2 = { show : true, text: 'ATRAS'};
              this.rest.showModes();
          } else {
              // console.log('final3');
              this.prepareDataProduct();
          }
    } else if (this.rest.alertType === 'booking') {
          // console.log('pulse aqui ');
          this.prepareDataProduct();
          this.rest.closeModal();
    } else if (this.rest.alertType === 'bill') {
          this.rest.closeModal();
    } else if (this.rest.alertType === 'confirm') {
          this.rest.closeModal();
    } else if (this.rest.alertType === 'language') {
          this.rest.closeModal();
    }
  }

  prepareDataProduct() {
    // console.log('price selected ' + this.rest.currentProduct.price_translations);
    const price = this.rest.priceSelected !== -1 && this.rest.currentProduct.price_translations !== undefined
    && this.rest.currentProduct.price_translations.values !== undefined
    && this.rest.currentProduct.price_translations.values[this.rest.priceSelected]
    !== undefined ? this.rest.currentProduct.price_translations.values[this.rest.priceSelected] : 0;

    // console.log('price ' + this.rest.currentProduct.price_translations.values[this.rest.priceSelected] );

    const product_modes = {
      product : this.rest.currentProduct,
      modes :  this.rest.selectedModes,
      date : this.date,
      hour: this.time,
      price : price,
      amount : this.rest.countProduct
    };

    this.rest.cartProducts.push(product_modes);
    // const cartButton = document.getElementById('cart-button');
    // cartButton.className = 'btn badge animate-color-on';

    // console.log('cart producrs ' + ( this.rest.cartProducts.length > 0 ));
    this.rest.countProduct = 1;
    this.rest.indexMode = 0;
    this.rest.selectedModes = [];

    this.rest.closeModal();

    this.rest.presentToast('Producto añadido al carrito');
  }

  onTextChange() {
    this.rest.restartStandByTime();
      console.log('on text change');
  }

  addAttendees() {
      this.attendeesAmount++;
  }

  subtractAttendees() {
    if (this.attendeesAmount > 1) {
        this.attendeesAmount--;
    }
  }
}
