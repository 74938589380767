import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicStorageModule } from '@ionic/storage';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ModalPageModule } from './modal/modal.module';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Hotspot } from '@ionic-native/hotspot/ngx';
import { File } from '@ionic-native/file/ngx';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/langs/', '.json');
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot({hardwareBackButton : false, animated: false}), IonicStorageModule.forRoot(),
    AppRoutingModule, 
    HttpClientModule,
    BrowserAnimationsModule,
    ModalPageModule,
    TranslateModule.forRoot({ // <--- add this
    loader: { // <--- add this
      provide: TranslateLoader, // <--- add this
      useFactory: (createTranslateLoader),  // <--- add this
      deps: [HttpClient] // <--- add this
    } // <--- add this
  }) ],
  providers: [
    StatusBar,
    SplashScreen,
    File,
    IonicStorageModule,
    AndroidPermissions,
    Hotspot,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
