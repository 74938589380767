import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastController, ModalController } from '@ionic/angular';
import * as constants from '../constants';
import * as constantsApps from '../constantsApps';
import { TYPE_SERVICES, TYPE_PILLOWS, TYPE_EXPERIENCES, TYPE_ALL } from '../constants';
import { Platform } from '@ionic/angular';
import { AlertController, LoadingController, IonSlides } from '@ionic/angular';
import { Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
import { IurbanService } from '../services/iurban.service';
import { ModalPage } from '../modal/modal.page';
import Parse from 'parse';
import * as firebase from 'Firebase';

interface Order {
  objectId: string;
  roomNumber: string;
  processed: boolean;
  prepared: boolean;
  delivered: boolean;
  items: [];
  showDetails: boolean;
  icon: string;
  updatedAt: string;
  createdAt: string;
}

interface Item {
  objectId: string;
  orderId: string;
  productId: string;
  productName: string;
  modes: string;
  dish: string;
  price: string;
  amount: string;
  stock: boolean;
  updatedAt: string;
  createdAt: string;
}

@Injectable({
  providedIn: 'root'
})

export class RestService {
  TAG = 'HOTEL_DIGITAL';

  configFirebase = {
    apiKey: 'AIzaSyB5PDMfZ9g0RMcLRbnj2fOTNCNOaHSSqb8',
    authDomain: 'hotel-digital-c67c1.firebaseapp.com',
    databaseURL: 'https://hotel-digital-c67c1.firebaseio.com',
    projectId: 'hotel-digital-c67c1',
    storageBucket: 'hotel-digital-c67c1.appspot.com',
  };

  database: any;

  alert: any;
  modal: any;
  loading: any;
  levels = new Array();
  level = 0;
  slides: IonSlides;
  uiTypeIurban = false;
  license = '';
  nameDevice = '';
  mac = '';
  appID: any;
  source: any;
  languages: any;
  imageLogo: any;
  imageLang = '../../assets/imgs/language.png';
  langIndexes: Map<number, number>;
  contentTab: any;
  standbyTimer: any;
  showToolBar = true;
  showLocation = true;
  showingModal = false;
  modalHeight = '50%';
  modalHeightHeader = '60%';
  modalHeightContent = '60%';
  modalHeightFooter = '60%';
  modalMarginTop = '40%';
  modalAction = '';

  lastUpdateTime = 0;
  showingStandBy = false;
  secondsLapsedLastUpdate = 0;

  textInput = '';

  inHome = false;

  // Modal vars
  alertType: string;
  checksFilters: any [][] = [[{}], [{}]];
  checksModes: any [][] = [[{}], [{}]];
  selectedFilters = new Array();
  selectedFiltersString = '';
  selectedModes = new Array();
  parentsFilters: any = [];
  indexMode = 0;
  titleModal = '';
  messageModal = '';

  btnModal1 = { show : true, text: 'CANCELAR'};
  btnModal2 = { show : true, text: 'SIGUIENTE'};
  btnModal3 = { show : true, text: 'SIGUIENTE'};
  priceSelected = -1;
  countProduct = 0;
  cartProducts = [];
  maxAddProducts = 50;
  productToDelete: any;
  myInput: any;
  showInfoScreen = false;
  showInfoMessage = false;

  // Configuration for each Slider
  slideOpts = {
    /*initialSlide: 0,
    slidesPerView: 1,
    speed: 1000,
    delay: 0,
    loop: true,
    pager: false,
    autoplayDisableOnInteraction: false*/
    loop: true
  };

  consts: any;
  standByTime = 1 * 1000;

  standByImages = [];

  imageTest: any;
  response: any;
  currentProduct: any;
  currentLink: any;
  lastUrl: any;
  urlTranslation: string;
  appStarted = false;

  // url params
  url = '';
  hotel = '001';
  bookingNumber = '002-00016610';
  room = '012';
  usedLang = 1;
  codeUsedLang = 'es';
  typeReference = TYPE_ALL;
  // typeReference = TYPE_SERVICES;
  // typeReference = TYPE_PILLOWS;
  // typeReference = TYPE_EXPERIENCES;
  token: string;
  // token = '$2y$10$5SE/vFeBA4mae7djMT.MnOmmK1EuGbHhVunpYzAZv4WZKw67.YzbC';

  colorText1 = '#FFFFFF';
  colorText2 = '#660000';
  colorText3 = '#FFFFFF';
  colorbackgroundItems = '#CC0000';
  colorbackgroundButtons = '#ffcc66';
  colorbackgroundApp = '#000000';

  stats = [];
  statsTouchJSONObject = [];
  statsCallWaiterJSONObject = [];
  statsBillCardJSONObject = [];
  statsBillCashJSONObject = [];
  statsAddProductJSONObject = [];
  statsOrderJSONObject = [];
  storageVar: any;

  showItemList: boolean;
  showAppsList: any;
  idApps: any;

  iurban: IurbanService;

  translateService: TranslateService;
  mBtnUnlockPressed = 0;
  contTouch = 0;
  showingAdminPanel = false;

  // LICENSE VARS
  infoText = '';
  showProgressbar = true;
  showIntroduceLicense = false;
  licenseWelcome = 'Bienvenido al sistema iTable.';
  introduceLicense = 'Por favor introduzca su licencia';
  showRedoButton = false;
  location: any;
  showClickableLayer = false;
  itemsByRow = [];
  backBtnDisabled = false;
  online = true;
  marginNotif = '0px';

  // BACK4APP
  client: Parse.LiveQueryClient;
  subscription: any;
  orderId: any;
  order: any;
  allow_cart = true;

  constructor(private httpClient: HttpClient, private toastController: ToastController, public platform: Platform,
              private alertCtrl: AlertController, private router: Router, public loadingController: LoadingController,
              private storage: Storage, private modalController: ModalController) {
      this.storageVar = storage;
      this.consts = constants;

      // this.initBack4App();
      this.initFirebase();
  }

  saveLicense() {
    this.storage.set('license', this.license);
    this.storage.set('nameDevice', this.nameDevice);
    this.storage.set('mac', this.mac);
   // this.showInfoDialog('saving license... ' + this.license);
  }

  startTimer() {
     console.log('in start timer, standby time  ' + this.standByTime);
     this.standbyTimer = setTimeout( () => {
        if (this.router.url === '/link') {
            this.showClickableLayer = true;
            console.log('showing clickable layer');
              // if iframe page show clickable layer, then user has 2 seconds to touch screen to prevent standby shows
            this.standbyTimer = setTimeout( () => {
                  this.showClickableLayer = false;
                  if (this.alert !== undefined) {
                      this.alert.dismiss();
                  }

                  this.closeModal();

                  this.router.navigateByUrl('/standby');
             }, 5000);
        } else {
            if (this.alert !== undefined) {
                this.alert.dismiss();
            }
            // console.log('stand by time ' + this.modal);
            this.closeModal();
            // console.log('cart ' + this.cartProducts.length);
            this.router.navigateByUrl('/standby');
        }
    }, this.standByTime);
  }

  restartStandByTime() {
    // this.modal.dismiss();
    if (this.consts.STAND_BY_ACTIVE && this.appStarted) {
        console.log('in restart standby');
        clearTimeout(this.standbyTimer);

    /*let api = constants.API;
    if (api === constants.IURBAN) {*/
        this.startTimer();
    // }
    }
  }

  showFilters() {
    this.titleModal = 'FILTROS DISPONIBLES';
    this.messageModal = '';
    this.btnModal1 = { show : false, text: ''};
    this.btnModal2 = { show : true, text: 'LIMPIAR'};
    this.btnModal3 = { show : true, text: 'ACEPTAR'};
  }

  showLanguage() {
    this.titleModal = 'IDIOMAS';
    this.messageModal = '';
    this.btnModal1 = { show : false, text: ''};
    this.btnModal2 = { show : false, text: ''};
    this.btnModal3 = { show : true, text: 'CANCELAR'};
  }

  showBill() {
    this.alertType = 'bill';
    this.titleModal = 'ELIJA UN MODO DE PAGO';
    this.messageModal = '';
    this.btnModal1 = { show : false, text: ''};
    this.btnModal2 = { show : false, text: ''};
    this.btnModal3 = { show : true, text: 'CANCELAR'};
  }

  showConfirm(params) {
    this.alertType = 'confirm';

    if (this.modalAction === 'waiter') {
        this.titleModal = 'INFORMACIÓN';
        this.messageModal = '¿Desea llamar al camarero?';
    } else if (this.modalAction === 'deleteProduct') {
        this.productToDelete = params;
        this.titleModal = 'INFORMACIÓN';
        this.messageModal = '¿Eliminar ' + this.cartProducts[this.productToDelete].product.name_translations[this.usedLang] + '?';
    }

    this.btnModal1 = { show : true, text: 'ACEPTAR'};
    this.btnModal2 = { show : false, text: ''};
    this.btnModal3 = { show : true, text: 'CANCELAR'};
  }

  showInput() {
    this.alertType = 'input';
    this.titleModal = 'PANEL DE ADMINISTRADOR';
    if (this.modalAction === 'password') {
        this.messageModal = 'Introduce una contraseña';
    } else if (this.modalAction === 'location') {
        console.log('en location aquiii');
        this.messageModal = 'Registre la zona del dispositivo';
    }
    this.btnModal1 = { show : true, text: 'ACEPTAR'};
    this.btnModal2 = { show : false, text: ''};
    this.btnModal3 = { show : false, text: ''};
  }

  showPrices() {
    this.alertType = 'prices';
    this.titleModal = '¿QUÉ DESEA?';
    this.btnModal1 = { show : true, text: 'CERRAR'};
    this.btnModal2 = { show : false, text: ''};

    if (this.currentProduct.modes.length > 0) {
      // console.log('on next pressed');
      this.btnModal3 = { show : true, text: 'SIGUIENTE'};

    } else {
      // console.log('final3');
      // this.prepareDataProduct();
      this.btnModal3 = { show : true, text: 'ACEPTAR'};
    }
  }

  showModes() {
        this.alertType = 'modes';
        this.titleModal = 'ELIJA: ' + this.currentProduct.modes[this.indexMode].name_translations[this.usedLang].toUpperCase();
        this.btnModal1 = { show : true, text: 'CERRAR'};

        if (this.currentProduct.price_translations.values != undefined && this.currentProduct.price_translations.values.length > 0) {
            this.btnModal2 = { show : true, text: 'ATRAS'};
        } else {
            this.btnModal2 = { show : false, text: 'ATRAS'};
        }

        this.btnModal3 = { show : true, text: 'SIGUIENTE'};
  }

  showBooking() {
    this.alertType = 'booking';

    this.titleModal = 'Formulario de reserva';
    this.btnModal1 = { show : true, text: 'CANCELAR'};

    if (this.currentProduct !== undefined &&  this.currentProduct.modes > 0 ||
      ( this.currentProduct.price_translations.values != undefined && this.currentProduct.price_translations.values.length > 0)) {
        this.btnModal2 = { show : true, text: 'ATRAS'};
    } else {
        this.btnModal2 = { show : false, text: ''};
    }

    if (this.currentProduct.id_product_interface === this.consts.INTERFACE_PRODUCT_TYPE_EVENT) {
        this.btnModal3 = { show : true, text: 'RESERVAR'};
    } else {
        this.btnModal3 = { show : true, text: 'PEDIR'};
    }
  }

  showAddProduct() {
    this.alertType = 'addProduct';

    this.titleModal = 'Añadir producto';

    this.btnModal1 = { show : true, text: 'CERRAR'};
    this.btnModal2 = { show : false, text: ''};
    this.btnModal3 = { show : true, text: 'SIGUIENTE'};
  }

  getPrinter() {
    console.log(this.response.printers[0].id);

    const printer = this.response.printers.filter(x => x.id == this.response.configuration.id_printer);

    console.log('printer: ' + JSON.stringify(printer));
    return printer;
  }

  async openModal(alertType: string, action: string, params: any) {
    this.modalAction = action;
    this.alertType = alertType;
    this.messageModal = '';

    this.showingModal = true;
    this.modalHeight = '50%';

    this.initModesModel();
    this.initPricesModel();

    if (alertType === 'addProduct' ) {
      this.modalHeight = '20%';
      this.showAddProduct();
    } else if (alertType === 'modes' ) {
        this.showModes();
    } else if (alertType === 'filters') {
        this.showFilters();
    } else if (alertType === 'prices') {
        this.showPrices();
    } else if (alertType === 'booking') {
        this.showBooking();
    } else if (alertType === 'input') {
        this.modalHeight = '25%';
        this.showInput();
    } else if (alertType === 'bill') {
        this.modalHeight = '20%';
        this.showBill();
    } else if (alertType === 'confirm') {
        this.modalHeight = '20%';
        this.showConfirm(params);
    } else if (alertType === 'language') {
        this.modalHeight = '20%';
        this.showLanguage();
    }

    this.modal = await this.modalController.create({
      component: ModalPage,
      cssClass: 'ion-input-modal',
      componentProps: {
        'alertType': alertType
      },
      showBackdrop: true,
      backdropDismiss: false,
    });

    /*this.modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        // this.dataReturned = dataReturned.data;
        // alert('Modal Sent Data :'+ dataReturned);
        this.showingModal = false;
        console.log('on dismiss');
      }
    });*/

    // let present = await this.modal.present();

    // console.log('input ' +this.myInput);

    // this.myInput.setFocus();
    this.log(this.TAG, 'in show modal');


    return await this.modal.present();
  }

  deleteProduct(i) {
    console.log('delete product');

    this.cartProducts.splice(i, 1);
  }

  closeModal() {
    this.log(this.TAG, 'in close modal');
    if (this.showingModal) {
        this.textInput = '';      
        this.selectedModes = new Array();
        this.initModesModel();

        try {
          this.modalController.dismiss();
        } catch (error) {
          console.log('error closing modal ' + error);
        }
     }
  }

  getTime(time) {
    const hour = time.substr(0,
      time.indexOf(':') );

    const minute = time.substr(
      time.indexOf(':') + 1,
      time.length );

    return {
      hour: hour,
      minute: minute
    };
  }

  getDate(dateString, hour, minute) {
    const day = dateString.substr(0, dateString.indexOf('/'));
    const month = dateString.substr(dateString.indexOf('/') + 1, dateString.lastIndexOf('/') - 3) - 1;
    const year = dateString.substr(dateString.lastIndexOf('/') + 1, dateString.length);
    return new Date(year, month, day, hour, minute, 0, 0);
  }

  restartAutoPlay(slides: IonSlides) {
    // this.slides.startAutthis.slides.startAutoplay();
    this.level = 0;
    this.router.navigateByUrl('/home');


    clearTimeout(this.standbyTimer);
    this.slides.stopAutoplay();
    this.startTimer();

    setTimeout( () => {
        this.slides.startAutoplay();
     }, 1000);
  }

  async presentLoading(msg: any) {
   this.loading = await this.loadingController.create({
    message: msg
  });
   await this.loading.present();
 }

getCartBtnClass() {
  const cartButton = document.getElementById('cart-button');
  // cartButton.setAttribute('data', '' + this.cartProducts.length);
  return this.cartProducts.length > 0 ? 'btn animate-color-on' : 'btn';
  // return 'btn';
}

getData() {
  return this.cartProducts.length;
}

setupInterface() {
  document.documentElement.style.setProperty('--color1', this.colorbackgroundItems + ' !important');
  document.documentElement.style.setProperty('--ion-background-color', 'transparent');
  document.documentElement.style.setProperty('--button-background-color', this.colorbackgroundButtons);
}

async presentToast(message) {
  const toast = await this.toastController.create({
    message: message,
    duration: 2000,
    position: 'top'
  });
  toast.present();
}

checkPassword(password: string) {
  this.log(this.TAG, 'on password ' + password);
  switch (password) {
    case 'Rl1c3': {
       this.log(this.TAG, 'removing license...');

       this.storage.remove('license');
       this.storage.remove('nameDevice');
       this.storage.remove('mac');
       this.storage.remove('appID');
       this.storage.remove('location');

       this.showProgressbar = false;
       this.showIntroduceLicense = true;
       this.infoText = this.introduceLicense;
       this.showRedoButton = false;

       this.license = '';
       this.nameDevice = '';
       this.mac = '';

       this.log(this.TAG, 'before android license... ' + this.isAndroid());

       if (this.isAndroid()) {
          (<any>window).UtilsAndroid.getMacAddress(this.onMACsuccess.bind(this), this.onMACfailure.bind(this));
       } else {
        this.router.navigateByUrl('/license-manager');
       }
       clearTimeout(this.standbyTimer);
       this.closeModal();
       break;
    }
    case 'ch4pp': {
       // console.log('change app');

       this.storage.remove('appID');
       this.showItemList = false;
       this.showAppsList = true;
       // this.iurban.askForActualApplication();

       clearTimeout(this.standbyTimer);
       this.closeModal();
       this.router.navigateByUrl('/license-manager');
       break;
    }
    case 'c4rd': {
      console.log('en card');
      this.storageVar.remove('idCardChosen');
      this.showItemList = true;

      clearTimeout(this.standbyTimer);
      this.closeModal();
      this.router.navigateByUrl('/home');
      break;
    }
    case 'cl0c': {
      console.log('en location');

      this.modalAction = 'location';
      this.showInput();
      break;
    }
    case 'w1f1': {
      console.log('change wifi');

      clearTimeout(this.standbyTimer);
      this.closeModal();
      this.router.navigateByUrl('/networks');
      break;
    }
    case '.3x1t.': {
        console.log('on exit ');
        this.log(this.TAG, 'on exit');
        this.closeModal();

        if (this.source !== 'dom' && this.platform.is('android')) {
            (<any>window).UtilsAndroid.chooseLauncher(null, null);
            (<any>window).UtilsAndroid.enablePullNotificationTouch(null, null);
        }
        break;
    }
    case 'n0c4rt': {
        this.allow_cart = false;
        this.storageVar.set('allow_cart', false);
        this.closeModal();
        break;
    }
    case 'c4rt': {
        this.allow_cart = true;
        this.storageVar.set('allow_cart', true);
        this.closeModal();
        break;
    }
    default: {
      this.presentToast('La contraseña no existe');
      this.closeModal();
      break;
    }
  }
  this.showingAdminPanel = false;
  this.textInput = '';
}

onUnlockButton() {
  console.log('on unlock');
  // if (!this.showingAdminPanel) {
  const date = new Date();
  const seconds = date.getSeconds();

  // console.log('millis ' + seconds);
  this.log(this.TAG, 'lapsed ' + (seconds - this.mBtnUnlockPressed));
   // console.log('lapsed ' + ( (seconds - this.mBtnUnlockPressed) ));
   const lapsed = (seconds -  this.mBtnUnlockPressed) ;

  if (lapsed === 0) {
      // console.log('perfe');
      if (this.contTouch >= 2) {
         // this.showAdminPanel();
        // alert('showing admin panel');
         this.openModal('input', 'password', null);

         this.contTouch = 0;
      } else {
         this.contTouch++;
      }
  } else {
    this.contTouch = 0;
  }
  this.mBtnUnlockPressed = seconds;
}


onUnlockLauncher() {
  console.log('on unlock');
  // if (!this.showingAdminPanel) {
  const date = new Date();
  const seconds = date.getSeconds();

  // console.log('millis ' + seconds);
  this.log(this.TAG, 'lapsed ' + (seconds - this.mBtnUnlockPressed));
   // console.log('lapsed ' + ( (seconds - this.mBtnUnlockPressed) ));
   const lapsed = (seconds -  this.mBtnUnlockPressed) ;

  if (lapsed === 0) {
      // console.log('perfe');
      if (this.contTouch >= 2) {
        if (this.isAndroid()) {
          (<any>window).UtilsAndroid.enablePullNotificationTouch(null, null);
          (<any>window).UtilsAndroid.chooseLauncher(null, null);
        }

         this.contTouch = 0;
      } else {
         this.contTouch++;
      }
  } else {
    this.contTouch = 0;
  }
  this.mBtnUnlockPressed = seconds;
}

getColSize(cartButton: boolean) {
  let buttonsSelected = 0;
  let size = 0;
  let cartButtonSize = 12;

  if (this.response.configuration.call_waiter) {
      buttonsSelected++;
  }
  if (this.response.configuration.get_bill) {
      buttonsSelected++;
  }

  if (buttonsSelected === 0) {
      return cartButton ? cartButtonSize : size;
  } else if (buttonsSelected === 1) {
      size = 6;
      cartButtonSize = 6;
      return cartButton ? cartButtonSize : size;
  } else if (buttonsSelected === 2) {
      size = 4;
      cartButtonSize = 4;
  }
  return cartButton ? cartButtonSize : size;
}

async showInfoDialog(msg: any) {
  this.alert = await this.alertCtrl.create({
    header: 'Información',
    cssClass: 'alertCustomCss',
    message: msg,
    buttons: ['OK']
  });
  return await this.alert.present();
}

changeLanguage(id: any, code: any, tabNumber: number) {
  console.log('id language ' + id);
  this.usedLang = id;
  this.codeUsedLang = code;
  this.imageLang = this.languages[this.langIndexes.get(this.usedLang)].image;

  if (this.router.url === '/product-details-page') {
    switch (tabNumber) {
      case -1: {
        this.contentTab = this.currentProduct.description_translations[this.usedLang];
        console.log('change language ' + this.currentProduct.description_translations[this.usedLang]);
        break;
     }
      case 0: {
         this.contentTab = this.currentProduct.description_translations[this.usedLang];
         break;
      }
      case 1: {
         this.contentTab = this.currentProduct.terms_translations[this.usedLang];
         break;
      }
    }
  }


  this.translateService.setDefaultLang(this.codeUsedLang);
  this.translateService.use(this.codeUsedLang);

  this.restartStandByTime();
  this.closeModal();
}

initFiltersModel() {
  if  (this.response.filters_without !== undefined) {
      for (let i = 0; i < this.response.filters_without.length; i++) {
          this.parentsFilters[i] = { showParent: false };
          for (let e = 0; e < this.response.filters_without[i].filter_elements.length; e++) {
              this.checksFilters[i][e] = { showParent: true, isChecked: false};
          }
      }
  }
}

initModesModel() {
 this.checksModes = [[{}], [{}], [{}], [{}], [{}], [{}], [{}]];

 if (this.currentProduct !== undefined && this.currentProduct.modes !== undefined) {
    for (let i = 0; i < this.currentProduct.modes.length; i++) {
      for (let e = 0; e < this.currentProduct.modes[i].submodes.length; e++) {
              this.checksModes[i][e] = { isChecked: false};
      }
    }
  }
}

initPricesModel() {
  if (this.currentProduct !== undefined) {
    if (this.currentProduct.price_translations.values != undefined && this.currentProduct.price_translations.values.length > 0) {
      this.priceSelected = 0;
  }
}
}

onPrinTicketSuccess(result) {
  // this.doSomething(result);
}

onPrinTicketFailure(err) {
  // this.doSomething(err);
}

onClickApp(index: number) {
  this.infoText = 'Inicando aplicación...';
  this.showProgressbar = true;
  this.showAppsList = false;
  this.appID = this.idApps[index].id;
  console.log('on click app ' + this.appID);

  this.storageVar.set('appID', this.appID);
  this.iurban.getData(this.appID, false);
}

storeItemsByColumns() {
  let lastIndex = 0;
  this.itemsByRow = [];
  for (let row = 0; row < this.levels[this.level].length; row ++) {
      this.itemsByRow[row] = {
        column1 : this.levels[this.level][lastIndex + row],
        column2 : this.levels[this.level][lastIndex + row + 1]
      };
      lastIndex = row + 1;
  }
}

getItem (item: any) {
  if (item.type === constants.ITEM_TYPE_PRODUCT) {
    if (item.product != undefined) {
        return item.product;
    } else {
        return item;
    }
  } else if (item.type === constants.ITEM_TYPE_LINK) {
      return item.link;
  } else {
      return item;
  }
}

isProduct(item: any) {
  return item.type === constants.ITEM_TYPE_PRODUCT || item.product != undefined;
}

getImageByType(item: any) {
  const realItem = this.getItem(item);
  let image;
  let api = constants.API;

  image = (api === constants.LOCALHOST ? "data:image/jpeg;base64," +
    (realItem.image_1.replace(/['"]+/g, '')) : realItem.image_1);

  if (image === null) {
      image = '../../assets/imgs/no_photo.png';
  }

  // console.log('in image ' + this.image_he);
  return image;
}

getItemsByRow(i, col) {
  if (col === 1) {
      // console.log('i have ' + (this.itemsByRow[i].column1 === undefined));
      return this.itemsByRow[i].column1;
  } else if (col === 2) {
      // console.log('i have ' + this.itemsByRow[i].column2 === undefined);
      return this.itemsByRow[i].column2;
  }
}

addProduct() {
  if (this.countProduct < this.maxAddProducts) {
      this.countProduct ++;
    }
}

subtractProduct() {
  if (this.countProduct > 0) {
        this.countProduct --;

        this.cartProducts.splice(this.cartProducts.findIndex(x => x.product.id == this.currentProduct.id), 1);
  }
}

getToolbarClass() {
  let number_of_columns = 0;

  if (this.response.configuration.call_waiter === 1) {
      number_of_columns ++;
  }
  if (this.response.configuration.get_bill === 1) {
      number_of_columns ++;
  }
  if (this.response.configuration.skype === 1) {
      number_of_columns ++;
  }
  if (this.response.configuration.wifi === 1) {
      number_of_columns ++;
  }

  if (constants.SHOW_BUTTON_FOOD) {
      number_of_columns ++;
  }

  if (number_of_columns === 1) {
      return 'grid-one-col';
  } else if (number_of_columns === 2) {
      return 'grid-two-col';
  } else if (number_of_columns === 3) {
      return 'grid-three-col';
  }
}

goHome() {
  this.countProduct = 1;
  if (this.router.url === '/home') {
    if (this.level > 0) {
        this.level = 0;
    }
  } else {
    this.level = 0;
    this.router.navigateByUrl('/home');
  }

    this.restartStandByTime();
    this.storeItemsByColumns();
}

onBackPressed() {
  this.countProduct = 1;
   console.log('json levels ' + JSON.stringify(window.history));
  if (this.router.url === '/home') {

    if (this.level > 0) {
        this.level-- ;
    }
  } else {
    
    window.history.back();
    // this.router.navigateByUrl('/home');
    // console.log('navigate to home');
  }

  

  this.restartStandByTime();
  this.countProduct = 1;

  this.storeItemsByColumns();
}

getBtnStatus() {
  return this.level > 0 ? '1' : '0.3';
}

setupUI() {

  console.log(this.response.configuration.text_1);

  if (this.response.configuration.text_1 != undefined) {
      this.colorText1 = this.response.configuration.text_1;
  }

  if (this.response.configuration.text_2 != undefined) {
      this.colorText2 = this.response.configuration.text_2;
  }

  if (this.response.configuration.text_3 != undefined) {
      this.colorText3 = this.response.configuration.text_3;
  }

  if (this.response.configuration.color_1 != undefined) {
      this.colorbackgroundItems = this.response.configuration.color_1;
  }

  if (this.response.configuration.color_2 != undefined) {
      this.colorbackgroundButtons = this.response.configuration.color_2;
  }

  if (this.response.configuration.color_3 != undefined) {
      this.colorbackgroundApp = this.response.configuration.color_3;
  }
}

setupStandBy(appStarted: boolean) {
  if (this.response != undefined && this.response.standby != undefined) {
      this.standByTime = this.response.standby.start_time * 1000;
  } else {
      this.standByTime = 3000;
  }

  if (!appStarted) {
      this.startTimer();
  }

  this.standByImages = [];
      // tslint:disable-next-line: forin
  for (let standby of this.response.standby.standby_elements) {
        this.checkIfMustShow(standby);
  }
  if (this.slides !== undefined) {
      this.slides.update();
  }
}

checkIfMustShow(currentSB: any) {
   console.log('standby programmed ' +  currentSB.programmed + ' active ' + currentSB.active);

   if (currentSB.active === 1) {
          if (!currentSB.programmed !== null && currentSB.programmed === 1) {
            // console.log('standby programmed');

              if (currentSB.programmed_date === 1) {
                  if (this.isOnDate(currentSB)) {
                      console.log('programmed week ' + currentSB.programmed_week + 'on day');

                      if (currentSB.programmed_week === 1) {
                          console.log('programmed_time ' + currentSB.programmed_time + ' on day ' + this.isOnDay(currentSB.days_week));

                          if (this.isOnDay(currentSB.days_week)) {

                              if (currentSB.programmed_time === 1 && this.isTimeInRange(currentSB)) {
                                  this.standByImages.push(currentSB.image);
                                  console.log('adding image ' + currentSB.image);
                              }
                          }
                      } else {
                          if (currentSB.programmed_time === 0 ||
                                  currentSB.programmed_time === 1 && this.isTimeInRange(currentSB)) {
                                    this.standByImages.push(currentSB.image);
                                    console.log('adding image ' + currentSB.image);
                          }
                      }
                  }
              } else {
                  console.log('WEEK ' + (currentSB.programmed_week === 1));
                  if (currentSB.programmed_week === 1) {
                    console.log('programmed_time ' + currentSB.programmed_time);

                    if (this.isOnDay(currentSB.days_week)) {
                          if (currentSB.programmed_time === 0
                                  || (currentSB.programmed_time === 1 && this.isTimeInRange(currentSB))) {
                                    this.standByImages.push(currentSB.image);
                                    console.log('adding image ' + currentSB.image);
                          }
                      }
                  } else {
                      if (currentSB.programmed_time === 0 ||
                              (currentSB.programmed_time === 1) && this.isTimeInRange(currentSB)) {
                                this.standByImages.push(currentSB.image);
                                console.log('adding image ' + currentSB.image);
                      }
                  }
              }
          } else {
              // console.log('standby not programmed');
              this.standByImages.push(currentSB.image);
          }
      }
}

isTimeInRange(currentSB) {
  const now = new Date();

  const dateStart = this.getDate(currentSB.date_start,
    this.getTime(currentSB.hour_start).hour, this.getTime(currentSB.hour_start).minute);

  const dateEnd = this.getDate(currentSB.date_end,
    this.getTime(currentSB.hour_end).hour, this.getTime(currentSB.hour_end).minute);

  return ((now >= dateStart && now <= dateEnd));
}

isOnDay(days_week: string) {
  const charArray = days_week.split('');

  // console.log('day ' + (new Date().getDay() + ' programmed ' + charArray[new Date().getDay()-1] +
  // ' week ' + days_week + (charArray[new Date().getDay()] === '1') ));

  return charArray[new Date().getDay() - 1] === '1';
}

isOnDate(currentSB) {
  const now = new Date();
  now.setHours(0);
  now.setMinutes(0);
  const dateStart = this.getDate(currentSB.date_start, 0, 0);
  const dateEnd = this.getDate(currentSB.date_end, 0, 0);

  /*console.log('is on date, date_start ' + dateStart + ' day now ' +
  now + ' equals? ' + (now >= dateStart) );

  console.log('is on date, date_start ' + dateStart.getDate() + ' date_end ' +
  dateEnd.getDate() + ' ' + (  (now >= dateStart && now <= dateEnd)  ));*/

  return ((now >= dateStart && now <= dateEnd)) ;
}

log(tag, message) {
    console.log( message);
    if (this.source !== 'dom' && this.platform.is('android')) {
        (<any>window).UtilsAndroid.log({TAG: tag, message: message}, null, null);
    }
}

logError(tag, message) {
  console.log(message);
  if (this.source !== 'dom' && this.platform.is('android')) {
      (<any>window).UtilsAndroid.logError({TAG: tag, message: message}, null, null);
  }
}

verifyTableOrRoom() {
  if (this.appID != constantsApps.CIVICITI && this.appID  != constantsApps.CIVICITI_DEMOS && this.source !== 'dom') {
      this.storageVar.get('location').then((val) => {
      console.log('verifying room ' + val);

      if (val === null || val === '') {
        this.openModal('input', 'location', null);
      } else {
        this.location = val;
      }

      this.location = val;
    });
  }
}

disableBackButton() {
  document.addEventListener("backbutton", function (e) {
    e.preventDefault();
  }, false );
}

isAndroid() {
  return this.source !== 'dom' && this.platform.is('android');
}

isBrowser() {
  return this.source === 'dom';
}

onMACsuccess(result) {
  // this.rest.mac = result;
  this.mac = result;

  this.log(this.TAG, 'in mac ' + this.mac);

  this.showIntroduceLicense = true;
  this.showProgressbar = false;

  // licenseWelcome = 'Bienvenido al sistema iTable.';
  // introduceLicense = 'Por favor introduzca su licencia';
  this.infoText = this.licenseWelcome + '\n' + this.introduceLicense;
  this.showIntroduceLicense = true;
  // this.checkLicenseIfConnect();

  this.showIntroduceLicense = true;
  this.log(this.TAG, 'in show introduce license ' + this.showIntroduceLicense);

  this.router.navigateByUrl('/license-manager');
}

onMACfailure(err) {
   alert('error ' + err);
}

initFirebase() {
  firebase.initializeApp(this.configFirebase);
  this.database = firebase.database();

}

initBack4App() {
  Parse.initialize('8PGy3bn21iRpZWTnhBJ1XeVJN9R34D2YWUifsItK', 'GDkzn0qBVNnQP8qWqSM9mvKDIdoupa5hiiyW6tPh');
  Parse.serverURL = 'https://testappsubdomain.back4app.io';

  const install = new Parse.Installation();
  install.set('deviceType', this.platform.platforms().toString());

  install.save(null, {
    success: (install) => {
      // Execute any logic that should take place after the object is saved.
      console.log('install id ' + install.id);

      this.client = new Parse.LiveQueryClient({
        applicationId: '8PGy3bn21iRpZWTnhBJ1XeVJN9R34D2YWUifsItK',
        serverURL: 'wss://' + 'testappsubdomain.back4app.io', // Example: 'wss://livequerytutorial.back4app.io'
        javascriptKey: 'GDkzn0qBVNnQP8qWqSM9mvKDIdoupa5hiiyW6tPh',
        masterKey: 'km8OU81AiZUK0gixWmIBT3UVquDHIDvM2PVY5vOx'
      });
      this.client.open();

    },
    error: (install, error) => {
      // Execute any logic that should take place if the save fails.
      // error is a Parse.Error with an error code and message.
      console.log('error ' + error.message);
    }
  });
}

createOrder() {
  // BACK4APP
  /*const Order = Parse.Object.extend('Order');
  this.order = new Order();

  this.order.set('roomNumber', this.room);
  this.order.set('processed', false);
  this.order.set('prepared', false);
  this.order.set('delivered', false);

  this.order.save()
  .then((order) => {
    const _order : Order = JSON.parse(JSON.stringify(order)) as Order;
    this.orderId = _order.objectId;

    this.createItems(_order.objectId);

    this.cartProducts = [];

    setTimeout( () => {
      console.log(' loaidng ' + (this.loading != null ));
      if (this.loading != null) {
        this.loading.dismiss();
      }
      this.showInfoDialog('pedido realizado');

      this.priceSelected = 0;

      // this.presentToast('Pedido realizado');

      const query = new Parse.Query('Order');
      query.equalTo('objectId', this.orderId);
      this.subscription = this.client.subscribe(query);
      this.subscription.on('update', order => {
        const _order : Order = JSON.parse(JSON.stringify(order)) as Order;

        if (_order.prepared && !this.order.prepared) {
          this.order.prepared = true;
          console.log('on prepared my order ' + _order.prepared);
          this.presentToast('Su pedido ha sido preparado');
        }

      });

    }, 1000);

  }, (error) => {
    // Save fails
    console.log('Failed to create new object, with error code: ' + error.message);
  });*/


  // FIREBASE
  const post = this.database.ref('orders/').push({
    createdAt: '' + new Date(),
    roomNumber: this.room,
    processed: false,
    prepared: false,
    delivered: false,
    items: this.createItems()
  });
  const orderId = post.key;

  // this.createItems();

  this.cartProducts = [];

  console.log('pongo listener ' + orderId);

  this.database.ref('orders/' + orderId).child('items').orderByChild('stock').equalTo(false).on('value', item => {
    // const _item : Item = JSON.parse(JSON.stringify(item)) as Item;

    console.log('sin stock ' + JSON.stringify(item));
    // console.log('sin stock ' + _item.stock + ' item ' + _item.objectId);

    this.presentToast('no queda ' + item.productName);

  });

  setTimeout( () => {
    console.log('itemId ' + post.key);
    if (this.loading != null) {
        this.loading.dismiss();
    }
  }, 2000);

}

createItems() {
  let modes = [];
  let items = [];

  console.log('price selected ' + this.priceSelected);
  let _id = 0;

  this.cartProducts.forEach(product => {
    console.log('product ' + JSON.stringify(product.product.name_translations[this.usedLang]) + ' dish ' +
    this.currentProduct.price_translations.keys[this.usedLang][this.priceSelected]);

    product.modes.forEach(mode => {
        console.log('mode ' + JSON.stringify(mode.name_translations[this.usedLang]));
        modes.push(mode.name_translations[this.usedLang]);
    });

   /*this.createItem(orderId, '' + product.product.id, '' + product.product.name_translations[this.usedLang], '' + modes, '' +
    this.currentProduct.price_translations.keys[this.usedLang][this.priceSelected], '' + product.price, '' + product.amount);*/

    items.push({
      id: _id,
      createdAt: '' + new Date(),
      productId: '' + product.product.id,
      productName: '' + product.product.name_translations[this.usedLang],
      modes: '' + modes,
      dish: '' + this.currentProduct.price_translations.keys[this.usedLang][this.priceSelected],
      price: '' + product.price,
      amount: '' + product.amount,
      stock: true
    });
    _id++;

  });

  return items;
}

createItem(_orderId, _productId, _productName, _modes, _dish, _price, _amount) {
    console.log('creating item...');

    // BACK4APP
    /*const Item = Parse.Object.extend('Item');
    const item = new Item();

    console.log('2 creating item... ' + dish);

    item.set('orderId', orderId);
    item.set('productId', productId);
    item.set('productName', productName);
    item.set('modes', modes);
    item.set('dish', dish);
    item.set('price', price);
    item.set('amount', amount);

    item.save()
    .then((item) => {
      const _item : Order = JSON.parse(JSON.stringify(item)) as Order;

      this.createItems(_item.objectId);

      this.cartProducts = [];
      // Success
      console.log('New item created with objectId: ' + JSON.stringify(_item.objectId));
    }, (error) => {
      // Save fails
      console.log('Failed to create new object, with error code: ' + error.message);
    });*/

    // FIREBASE
    const post = this.database.ref('items/').push({
      orderId: _orderId,
      createdAt: '' + new Date(),
      productId: _productId,
      productName: _productName,
      modes: _modes,
      dish: _dish,
      price: _price,
      amount: _amount,
      stock: true
    });
    const itemId = post.key;

    setTimeout( () => {
      console.log('itemId ' + post.key);
      if (this.loading != null) {
          this.loading.dismiss();
      }
    }, 2000);

  }

}